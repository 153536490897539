import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "jasonDinsmore",
  "categories": ["rails"],
  "date": "2018-03-02",
  "path": "/blog/paper-trail-scrapbook",
  "summary": "PaperTrail and PaperTrailScrapbook are two gems that can work in concert to track and view changes to your application's data. Today we take a look at how to get started, basic usage, examples, and a few caveats.",
  "title": "Gems, Some of Our Favorites - PaperTrail + PaperTrailScrapbook",
  "image": "./scrapbooks.jpeg",
  "seo": {
    "og": {
      "type": "article",
      "title": "Gems, Some of Our Favorites - PaperTrail + PaperTrailScrapbook",
      "description": "PaperTrail and PaperTrailScrapbook are two gems that can work in concert to track and view changes to your application's data. Today we take a look at how to get started, basic usage, examples, and a few caveats."
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia",
      "creator": "@dinjas"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is the first in a series of blog posts taking an introductory look at various Ruby gems that we find useful and regularly use or have used in our projects over the years.`}</p>
    <p>{`Today I am going to cover two related gems: PaperTrail, and PaperTrailScrapbook. These gems facilitate keeping an auditable trail of changes made to rows of a tracked table in your Rails application's database.`}</p>
    <hr></hr>
    <h2>{`PaperTrail`}</h2>
    <h3>{`Overview`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/airblade/paper_trail"
      }}>{`PaperTrail gem`}</a>{` allows you to track changes made to your Rails models over time. Any time a tracked record in your database is changed, it creates a corresponding entry in a `}<inlineCode parentName="p">{`PaperTrail::Version`}</inlineCode>{` table. It accomplishes this by tying into the ActiveRecord callback chain and storing a new version when the record is created, updated, or destroyed.`}</p>
    <h3>{`Setup`}</h3>
    <p>{`To start using PaperTrail, you'll need to follow the steps outlined in the README on the project's GitHub page, which essentially guide you through the process of adding the gem to your gemfile, bundling, and then generating and running a migration that creates the `}<inlineCode parentName="p">{`PaperTrail::Version`}</inlineCode>{` table. This table will be leveraged to store an entry for each "version" of your model.`}</p>
    <h3>{`Use`}</h3>
    <p>{`Once you've got the gem set up, you can start using it. To start tracking versions for a model, you'll just need to add `}<inlineCode parentName="p">{`has_paper_trail`}</inlineCode>{` to your model file (usually near the top, inside the class):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`MyModel`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationRecord`}</span>{`
  has_paper_trail
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Now that your versions are being tracked, you will be able to go into your Rails console and view the various versions of your models using PaperTrail's API.`}</p>
    <p>{`For a specific object, `}<inlineCode parentName="p">{`obj`}</inlineCode>{`, you can access a collection of its versions using: `}<inlineCode parentName="p">{`obj.versions`}</inlineCode>{`. This will show you a list of versions that each have this structure:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#<PaperTrail::Version:0x007fe111b03f70> {`}</span>{`
                    `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:id`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`314159`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
             `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:item_type`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Invoice"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
               `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:item_id`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`555`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                 `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:event`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"update"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
             `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:whodunnit`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1234"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:object`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"---\\nid: 5455\\nactor_id: 2183\\nsale_id: \\namount: !ruby/object:BigDecimal 27:0.1276E3\\nauthorization_id: \\npaid_on: \\ncreated_at: 2018-02-15 17:14:16.718224000 Z\\nupdated_at: 2018-02-16 17:14:16.718224000 Z\\nstatus: active\\n"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:created_at`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Mon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`19`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Feb`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2019`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`59`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`49`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`08`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:object_changes`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"---\\nsale_id:\\n- \\n - 123\\npaid_on:\\n- \\n- 2018-02-20 20:39:19.956256107 Z\\nauthorization_id:\\n- 412\\nupdated_at:\\n- 2018-02-19 20:39:19.956256107 Z\\n- 2018-02-19 19:59:49.270910041 Z\\n"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The fields of a version break down like this:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The primary key for this `}<inlineCode parentName="td">{`PaperTrail::Version`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`item_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of record being tracked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`item_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The primary key of the record being tracked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`event`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of change that occurred`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`whodunnit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The `}<inlineCode parentName="td">{`id`}</inlineCode>{` of the person making the change (you can set the class of the `}<inlineCode parentName="td">{`whodunnit`}</inlineCode>{` in your PaperTrail configuration, ie. `}<inlineCode parentName="td">{`User`}</inlineCode>{`, `}<inlineCode parentName="td">{`Actor`}</inlineCode>{`, etc.)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`object`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A serialized version of the object being updated in its pre-update state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`created_at`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When this version was created`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "right"
          }}><inlineCode parentName="td">{`object_changes`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A serialized list of changes that were applied to the object as shown in the `}<inlineCode parentName="td">{`object`}</inlineCode>{` field of the version`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`PaperTrail also provides the ability to revert an object to a previous state. To restore an object to a previous version, you can  call `}<inlineCode parentName="p">{`reify`}</inlineCode>{` (which means to make something abstract more concrete or real) on the version.`}</p>
    <p>{`For example, using the version from our last example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`last_version `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` object`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`versions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`last  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# gets PaperTrail::Version shown above`}</span>{`
puts last_version`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`reify

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Invoice`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`314159`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                  `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:id`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`314159`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:actor_id`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2183`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
             `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:sale_id`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
              `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:amount`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27.0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:authorization_id`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
             `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:paid_on`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:created_at`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Mon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`19`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Feb`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`59`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`49`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`08`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:updated_at`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Mon`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`19`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Feb`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`59`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`49`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`08`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
              `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:status`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'active'`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><inlineCode parentName="p">{`reify`}</inlineCode>{` will give you an instance of the object that corresponds to the version before the `}<inlineCode parentName="p">{`object_changes`}</inlineCode>{` have been applied, which matches what is in the `}<inlineCode parentName="p">{`object`}</inlineCode>{` field of the version. `}</p>
    <p>{`Calling `}<inlineCode parentName="p">{`reify`}</inlineCode>{` does not affect the object's current state in the database unless you call `}<inlineCode parentName="p">{`save`}</inlineCode>{` on the reified object. Doing so will persist the reified version to the database, creating a new version for the object in the process.`}</p>
    <h3>{`Caveats`}</h3>
    <p>{`PaperTrail falters a bit when tracking changes to a model's associations. For example, consider an `}<inlineCode parentName="p">{`Invoice`}</inlineCode>{` class that  `}<inlineCode parentName="p">{`has_many :line_items`}</inlineCode>{` where `}<inlineCode parentName="p">{`LineItem`}</inlineCode>{` `}<inlineCode parentName="p">{`belongs_to :invoice`}</inlineCode>{`. If you had an `}<inlineCode parentName="p">{`invoice`}</inlineCode>{` that has several `}<inlineCode parentName="p">{`line_items`}</inlineCode>{` associated with it, you could remove the line items by calling some code like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`invoice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`line_items `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
invoice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`save`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span></code></pre></div>
    <p>{`If you did so and took a look at the tracked versions of one of the `}<inlineCode parentName="p">{`line_items`}</inlineCode>{` that had been associated with the `}<inlineCode parentName="p">{`invoice`}</inlineCode>{`, you'd see that the removal of the `}<inlineCode parentName="p">{`invoice_id`}</inlineCode>{` from the `}<inlineCode parentName="p">{`line_item`}</inlineCode>{` was not captured in a version.`}</p>
    <p>{`PaperTrail has some information in the project's `}<a parentName="p" {...{
        "href": "https://github.com/airblade/paper_trail#4b1-known-issues"
      }}>{`README`}</a>{` mentioning this and suggests some "experimental" workarounds, but your mileage may vary.`}</p>
    <p>{`In my opinion, PaperTrail's API is a bit cumbersome and does not provide a concise summary of the changes that have been made to a model over time. The information is definitely there, but extracting it into a meaningful, complete history can be painful.`}</p>
    <p>{`This is where `}<a parentName="p" {...{
        "href": "https://github.com/tjchambers/paper_trail_scrapbook"
      }}>{`PaperTrailScrapbook`}</a>{` comes in.`}</p>
    <hr></hr>
    <h2>{`PaperTrailScrapbook`}</h2>
    <h3>{`Overview`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/tjchambers/paper_trail_scrapbook"
      }}>{`PaperTrailScrapbook`}</a>{` was conceptualized by `}<a parentName="p" {...{
        "href": "https://github.com/tjchambers"
      }}>{`Tim Chambers`}</a>{` in 2017. Its intent is to provide a human-readable summary of changes made to a model tracked by PaperTrail. It accomplishes this by providing a simple interface to obtain either a complete history of an object, or a list of changes a specific person has made. `}<em parentName="p">{`Disclaimer: I am a contributor on this project :)`}</em></p>
    <h3>{`Setup`}</h3>
    <p>{`To set up PaperTrailScrapbook, you'll just need to add `}<inlineCode parentName="p">{`gem 'paper_trail_scrapbook'`}</inlineCode>{` to your Gemfile and bundle your application. The other thing you'll need to do is specify the class of your app's `}<inlineCode parentName="p">{`whodunnit`}</inlineCode>{`. Most often, this is going to be `}<inlineCode parentName="p">{`User`}</inlineCode>{`. I'd recommend adding an initializer (e.g. `}<inlineCode parentName="p">{`config/initializers/paper_trail_scrapbook.rb`}</inlineCode>{`) for PaperTrailScrapbook.`}</p>
    <p>{`If your whodunnit class is `}<inlineCode parentName="p">{`User`}</inlineCode>{`, your configuration would look similar to this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`PaperTrailScrapbook`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`configure `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`config`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`
  config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`whodunnit_class `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`User`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Once you've got the configuration in place, restart your app server and you should be good to go.`}</p>
    <h3>{`Use`}</h3>
    <p>{`PaperTrailScrapbook currently has two modes of use, `}<inlineCode parentName="p">{`LifeHistory`}</inlineCode>{` and `}<inlineCode parentName="p">{`UserJournal`}</inlineCode>{`. Each mode provides a `}<inlineCode parentName="p">{`story`}</inlineCode>{` for a given object or `}<inlineCode parentName="p">{`whodunnit`}</inlineCode>{`. `}</p>
    <h4>{`LifeHistory`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`LifeHistory`}</inlineCode>{` module takes a model instance as its argument, and generates a list of changes made to that object over time. `}</p>
    <p>{`For example, the following query for history:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`widget `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

puts `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PaperTrailScrapbook`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`LifeHistory`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`widget`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`story`}</code></pre></div>
    <p>{`Could output the following story:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Friday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`08`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dec`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`01`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`AM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Fred`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Flintstone`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`fred`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@flinstone`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`com`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` created the following `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Tricky`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinner`}</span>{`
 • description`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • notes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Recommended`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` ages `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` an up
 • created by`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Fred`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Flintstone`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
 • pattern`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`386`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
 • price`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12.34`}</span>{`
 • status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inactive
 •`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Wednesday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dec`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`53`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Barney`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Rubble`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`barney`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@rubble`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`com`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` updated the following `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Recommended`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` ages `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` an up `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Recommended`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` ages `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`and`}</span>{` up
 • description`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span>{` fun spinning widget to keep your active fingers busy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`
 • price`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12.34`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12.99`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Thursday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`21`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dec`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`34`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Wilma`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Flintstone`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`wilma`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@flinstone`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`com`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` updated the following `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • price`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12.99`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11.99`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Wednesday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`03`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Jan`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`24`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`AM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Betty`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Rubble`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`betty`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@rubble`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`com`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` updated the following `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Tricky`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinner`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinning`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Trick`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{`
 • price`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11.99`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9.99`}</span>{`
 • status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inactive `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` active

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Wednesday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`05`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Jan`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`24`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Fred`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Flintstone`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`fred`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@flinstone`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`com`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` updated the following `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` info`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinning`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Trick`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinning`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Brontosaurus`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{`
 • price`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11.99`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9.99`}</span>{`
 • status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inactive `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` active`}</code></pre></div>
    <h4>{`UserJournal`}</h4>
    <p>{`The `}<inlineCode parentName="p">{`UserJournal`}</inlineCode>{` module takes a `}<inlineCode parentName="p">{`whodunnit`}</inlineCode>{` instance as its primary argument and generates a summary of changes made by that person over time. It also provides options to scope the summary to a specific class and/or date range. This can be really useful as your app ages since the number of changes made by a person could grow quite large over time.`}</p>
    <p>{`For example, searching by a specific `}<inlineCode parentName="p">{`whodunnit`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`fred `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Person`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

puts `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PaperTrailScrapbook`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`UserJournal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`fred`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`story`}</code></pre></div>
    <p>{`Could output the following history:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`Between`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Friday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`08`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dec`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`01`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`AM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`and`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Wednesday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`05`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Jan`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`24`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Fred`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Flintstone`}</span>{` made the following changes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Friday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`08`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dec`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`01`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`AM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` created `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Tricky`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinner`}</span>{`
 • description`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • notes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Recommended`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` ages `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` an up
 • created by`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Fred`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Flintstone`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
 • pattern`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`386`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
 • price`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12.34`}</span>{`
 • status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inactive
 •`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Wednesday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dec`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`31`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` updated `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Wobble`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`538`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • on_hand`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Thursday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`21`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dec`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`34`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` updated `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • favorite saying`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Yabba`}</span>{` dabba `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Yabba`}</span>{` dabba doo`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Wednesday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`05`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Jan`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`24`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` updated the following `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinning`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Trick`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinning`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Brontosaurus`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{`
 • price`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11.99`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9.99`}</span>{`
 • status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inactive `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` active`}</code></pre></div>
    <p>{`You can also scope changes to a specific class to hone in on the changes you're after. Consider the following where we look for changes Fred has made to instances of the `}<inlineCode parentName="p">{`Widget`}</inlineCode>{` class:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`fred `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Person`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

puts `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PaperTrailScrapbook`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`UserJournal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`fred`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` klass`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`story`}</code></pre></div>
    <p>{`Which would output the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`Between`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Friday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`08`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dec`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`01`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`AM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`and`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Wednesday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`05`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Jan`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`24`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Fred`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Flintstone`}</span>{` made the following changes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Friday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`08`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Dec`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2017`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`01`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`AM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` created `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Tricky`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinner`}</span>{`
 • description`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • notes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Recommended`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` ages `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` an up
 • created by`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Fred`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Flintstone`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
 • pattern`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`386`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
 • price`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12.34`}</span>{`
 • status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inactive
 •`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Wednesday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`05`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Jan`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`24`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` updated the following `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinning`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Trick`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinning`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Brontosaurus`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{`
 • price`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11.99`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9.99`}</span>{`
 • status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inactive `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` active`}</code></pre></div>
    <p>{`History can also be constrained to a specific period of time, using the `}<inlineCode parentName="p">{`start`}</inlineCode>{` and `}<inlineCode parentName="p">{`end`}</inlineCode>{` parameters:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`fred `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Person`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

puts `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PaperTrailScrapbook`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`UserJournal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`fred`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` klass`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
                                                start`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Date`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'2018-01-01'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 
                                                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`   `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Time`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`zone`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`now`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`story`}</code></pre></div>
    <p>{`Which would ouput this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`Between`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Monday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`01`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Jan`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`00`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`AM`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`00`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`00`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`and`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Friday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`23`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Feb`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`05`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`AM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Fred`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Flintstone`}</span>{` made the following changes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`On`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Wednesday`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`05`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Jan`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2018`}</span>{` at `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`24`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PM`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PST`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` updated the following `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
 • name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinning`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Trick`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Spinning`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Brontosaurus`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Widget`}</span>{`
 • price`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11.99`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9.99`}</span>{`
 • status`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inactive `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` active`}</code></pre></div>
    <h3>{`Customization`}</h3>
    <p>{`If your system allows for instances of your whodunnit class to be deleted, you may run across a situation where the whodunnit for a version no longer exists in your database. You can customize the behavior of PaperTrailScrapbook for that scenario by providing a proc for invalid whodunnits in your configuration file, as follows:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{` config`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`invalid_whodunnit `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` proc `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{`id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"** DELETED: `}<span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`#{`}</span>{`id`}<span parentName="span" {...{
                "className": "token delimiter tag"
              }}>{`}`}</span></span>{`**"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`Caveats`}</h3>
    <p>{`This project is still in its infancy, so you may hit occasional quirks or behavior that isn't ideal for your application. If you do, I'd consider submitting an issue or even a pull request. Any contributions are definitely welcomed and appreciated.`}</p>
    <p>{`That said, there are a few known behaviors that I'd like to mention.`}</p>
    <p>{`Querying for changes made by a person can take a `}<em parentName="p">{`very`}</em>{` long time if your project has several large tables and the person has been active. If you can, scoping by class or time period will improve performance drastically.`}</p>
    <p>{`Another area that could use improvement is in the presentation of serialized fields. `}</p>
    <p>{`Imagine you have a `}<inlineCode parentName="p">{`Container`}</inlineCode>{` class with a serialized Hash field, called `}<inlineCode parentName="p">{`capacity`}</inlineCode>{`. If you were to populate the `}<inlineCode parentName="p">{`capacity`}</inlineCode>{` field with the following hash:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"weight"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"min"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"25"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"max"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"50"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`and ran history on your object instance, you would see something like this for the change on `}<inlineCode parentName="p">{`capacity`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`• capacity`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`--`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`ruby`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`hash`}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:ActiveSupport`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`HashWithIndifferentAccess`}</span>{`
weight`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`ruby`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`hash`}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:ActiveSupport`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`HashWithIndifferentAccess`}</span>{`
min`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'25'`}</span>{`
max`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'50'`}</span>{`
added`}</code></pre></div>
    <p>{`and then if you updated the value to include `}<inlineCode parentName="p">{`height`}</inlineCode>{` limits so the hash was:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"weight"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"min"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"20"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"max"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"40"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"height"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"min"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"55"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"max"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"65"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`and re-ran history, you'd see this for the change:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`• capacity`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`--`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`ruby`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`hash`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`with`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`ivars`}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:ActionController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Parameters`}</span>{`
elements`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
weight`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`ruby`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`hash`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`with`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`ivars`}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:ActionController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Parameters`}</span>{`
elements`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
min`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'25'`}</span>{`
max`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'50'`}</span>{`
ivars`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`@permitted`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{`
ivars`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`@permitted`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`--`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`ruby`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`hash`}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:ActiveSupport`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`HashWithIndifferentAccess`}</span>{`
weight`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`ruby`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`hash`}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:ActiveSupport`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`HashWithIndifferentAccess`}</span>{`
min`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'20'`}</span>{`
max`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'40'`}</span>{`
height`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`ruby`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`hash`}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:ActiveSupport`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`HashWithIndifferentAccess`}</span>{`
min`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'55'`}</span>{`
max`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'65'`}</span></code></pre></div>
    <p>{`All of the information is there, but as you can see, it can get a little unwieldy to wrap your head around as the hash grows in size. Serialized arrays face similar challenges, but the amount of data displayed for a change is nowhere near as verbose.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`These two gems provide a great way to track changes made to your application's data.`}</p>
    <p><inlineCode parentName="p">{`PaperTrail`}</inlineCode>{` is a well established gem and is used by many applications in their production environment. `}</p>
    <p><inlineCode parentName="p">{`PaperTrailScrapbook`}</inlineCode>{` is hugely helpful for following changes made over time. It provides useful tools for observing how people are using your application and the sorts of changes they are making to data. I am excited to see how the project continues to evolve and grow as time goes on.`}</p>
    <h2>{`Next Time`}</h2>
    <p>{`In the next post in this series, we'll take a look at the handy-dandy `}<a parentName="p" {...{
        "href": "https://github.com/snusnu/procto"
      }}>{`Procto gem`}</a>{`, which provides an excellent way to turn "your ruby object into a method object".`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      